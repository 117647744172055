<template>
  <div class="content">
    <div class="flex">
      <div>
        <Menu />
      </div>
      <div class="w-full relative">
        <div
          class="flex items-center justify-between px-6 pt-6 pb-2 border-b-2 border-nColorTextInput"
        >
          <div class="flex items-center gap-2">
            <div class="font-InterExtraBold text-3xl text-nColorOrange">
              We would love to hear from you
            </div>
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/smiley.webp"
                alt="Game Jam Smiley"
                width="45"
                height="45"
              />
            </div>
          </div>
          <div id="menu-account">
            <button
              class="font-ZuumeMedium px-4 py-1 rounded-3xl text-xl border border-nColorYellow text-nColorYellow bg-nColorTextInput"
              @click.prevent="signOut()"
            >
              Sign out
            </button>
          </div>
        </div>
        <div class="text-white font-InterBold text-xl px-6 py-2">
          How can we help you?
        </div>
        <div
          class="bg-nColorTextInput rounded-3xl mx-6 mt-4 mb-4 text-white p-6"
        >
          <div class="flex gap-2">
            <div class="w-1/2">
              <input
                type="text"
                name="name"
                id="name"
                v-model="name"
                class="textInput"
                placeholder="Your full name"
              />
            </div>
            <div class="w-1/2">
              <input
                type="mobile"
                name="mobile"
                id="mobile"
                v-model="mobile"
                class="textInput"
                placeholder="Mobile number"
              />
            </div>
          </div>
          <div class="flex gap-2 mt-4">
            <div class="w-1/2">
              <p class="font-InterSemiBold text-white">
                What can we help you with?
              </p>
              <div class="radio-group mt-8">
                <label
                  v-for="(option, index) in options"
                  :key="index"
                  class="radio-selector"
                  :class="{ selected: selectedOption === option }"
                >
                  <input
                    type="radio"
                    :value="option"
                    v-model="selectedOption"
                    :id="`radio-${index}`"
                  />
                  <span class="radio-icon"></span>
                  <p class="text-white">{{ option }}</p>
                </label>
              </div>
            </div>
            <div class="w-1/2">
              <textarea
                name="message"
                id="message"
                v-model="message"
                class="textArea"
                style="resize: none"
                rows="6"
                placeholder="Tell us about the problem you’re facing"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end px-6">
          <div class="flex gap-2">
            <div class="text-sm inline-block mt-2 h-4">
              <div v-if="errorMsg" class="error text-red-600 rounded">
                <p class="text-sm">{{ errorMsg }}</p>
              </div>
            </div>
            <div>
              <button
                @click.once.prevent="submit()"
                :class="[
                  'bg-nColorYellow rounded-3xl w-32 h-8 text-nColorButtonText text-center uppercase text-2xl font-ZuumeSemiBold focus:outline-none',
                  { disabled: isSubmitLoading },
                ]"
                :disabled="isSubmitLoading"
              >
                <span v-if="isSubmitLoading" class="flex justify-center">
                  <svg
                    width="20"
                    height="6"
                    viewBox="0 0 120 30"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#fff"
                  >
                    <circle cx="15" cy="15" r="15">
                      <animate
                        attributeName="r"
                        from="15"
                        to="15"
                        begin="0s"
                        dur="0.8s"
                        values="15;9;15"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="fill-opacity"
                        from="1"
                        to="1"
                        begin="0s"
                        dur="0.8s"
                        values="1;.5;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                      <animate
                        attributeName="r"
                        from="9"
                        to="9"
                        begin="0s"
                        dur="0.8s"
                        values="9;15;9"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="fill-opacity"
                        from="0.5"
                        to="0.5"
                        begin="0s"
                        dur="0.8s"
                        values=".5;1;.5"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx="105" cy="15" r="15">
                      <animate
                        attributeName="r"
                        from="15"
                        to="15"
                        begin="0s"
                        dur="0.8s"
                        values="15;9;15"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="fill-opacity"
                        from="1"
                        to="1"
                        begin="0s"
                        dur="0.8s"
                        values="1;.5;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                  </svg>
                </span>
                <span v-else> Submit </span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex items-center mx-6 gap-4 mt-6">
          <div class="bg-nColorTextInput rounded-3xl text-white p-6">
            <div class="info-text">
              <h4 class="font-bold text-2xl">Email Address</h4>
              <p class="text-para-1">support@playda.io</p>
            </div>
          </div>
          <div class="bg-nColorTextInput rounded-3xl text-white p-6">
            <div class="info-text">
              <h4 class="font-bold text-2xl">Phone number</h4>
              <p class="text-para-1">+91-9620047457</p>
            </div>
          </div>
          <div class="bg-nColorTextInput rounded-3xl text-white p-6">
            <div class="info-text">
              <h4 class="font-bold text-2xl">Corporate Address</h4>
              <p class="text-para-1">
                No.02, 1st Floor, Jyothi Tower, Kumaracot Layout, Bangalore,
                Karnataka, India - 560001
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Dashboard/DesktopMenu.vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "Dashboard",
  components: { Menu },
  data() {
    return {
      data: null,
      options: [
        "I need help with payment",
        "QR code and Link not working",
        "I want to customize the platform entirely",
        "Others",
      ],
      selectedOption: null,
      isSubmitLoading: false,
      name: null,
      mobile: null,
      message: null,
      errorMsg: null,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    submit() {
      this.isSubmitLoading = true;
      if (this.name && this.mobile && this.selectedOption) {
        ApiService.post(apiResource.submitContactForm, {
          name: this.name,
          mobile: this.mobile,
          help_with: this.selectedOption,
          message: this.message,
        })
          .then(() => {
            this.isSubmitLoading = false;
            toast.success("Thank you! We will get back to you shortly!", {
              timeout: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            this.isSubmitLoading = false;
            this.errorMsg = error.response.data.msg;
          });
      } else {
        this.isSubmitLoading = false;
        this.errorMsg = "Please fill all the fields";
      }
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.content {
  height: 100dvh;
}
.textInput {
  @apply w-full text-white pt-2 pb-3 focus:outline-none bg-nColorTextInput border-b border-nColorLightGray;
}
.textArea {
  @apply border-b border-nColorLightGray block appearance-none focus:outline-none bg-transparent w-full py-2.5;
}
.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-selector {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.radio-selector.selected .radio-icon {
  width: 20px;
  height: 20px;
  background-color: #ffcb3b; /* Selected color */
  border-radius: 50%; /* Make the icon circular */
  border: 1px solid #ffcb3b; /* Optional: matching border color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; /* Unselected color */
  border: 1px solid #ffcb3b; /* Border for unselected state */
  border-radius: 50%; /* Make the icon circular */
  transition: background-color 0.3s ease; /* Smooth transition */
}

/* Style the radio input to be hidden */
input[type="radio"] {
  display: none;
}

/* When the radio input is checked, add the 'selected' class to its parent */
input[type="radio"]:checked + .radio-icon {
  background-color: #ffcb3b; /* Change the background color when selected */
  border-color: #ffcb3b;
}
</style>
